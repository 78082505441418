<template>
  <div class="tactic-wrapper d-flex flex-stack pb-4">
    <div class="d-flex">
      <div class="d-flex flex-column">
        <strong>{{ tactic.name }}</strong>
        <div class="text-gray-800">
            {{ tactic.description }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="form-check form-check-solid form-switch">
        <input class="form-check-input w-45px h-30px"
               type="checkbox"
               :id="`tactic-switch-${tactic.id}`"
               :checked="tactic.is_enabled"
               @change="handleChange">
        <label class="form-check-label" :for="`tactic-switch-${tactic.id}`"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "Tactic",

  props: {
    tactic: {
        type: Object,
        required: true,
    }
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected'
    }),
  },

  methods: {
    ...mapActions({
      enableTactic: 'shopify/shops/tactics/enable',
      disableTactic: 'shopify/shops/tactics/disable',
    }),

    handleChange(event) {
        if(!this.selectedShop) return;
        if(this.tactic.is_enabled === event.target.checked) return;

        if(event.target.checked) {
            this.enableTactic({
                shopId: this.selectedShop.id,
                tacticId: this.tactic.id,
            });
        } else {
            this.disableTactic({
                shopId: this.selectedShop.id,
                tacticId: this.tactic.id,
            });
        }
    }
  }
});
</script>

<style scoped>

</style>
<template>
  <b-card class="p-0">
    <div class="d-flex flex-wrap flex-stack">
      <div class="d-flex flex-column flex-column flex-grow-1 pe-8">
        <h3>Automation status</h3>
<!--        <div class="text-gray-800">-->
<!--          Lorem impsum ..-->
<!--        </div>-->
      </div>
      <div class="d-flex align-items-center justify-content-end flex-column mt-3">

        <automation-status-select />

      </div>
    </div>
  </b-card>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import AutomationStatusSelect from "./automationStatus/Select.vue";

export default defineComponent({
  name: "AutomationStatus",

  components: {
    AutomationStatusSelect,
  }

});
</script>

<style scoped>

</style>

import {defineComponent} from "vue";
import Tactic from "./tactics/Tactic.vue";
import {mapActions, mapGetters} from "vuex";
import {groupBy} from "lodash";

export default defineComponent({
  name: "Tactics",

  components: {
    Tactic
  },

  mounted() {
    this.fetchList();
  },

  watch: {
    'selectedShop.id'() {
      this.fetchList();
    }
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      list: 'shopify/shops/tactics/list',
      listIsFetching: 'shopify/shops/tactics/listIsFetching'
    }),
    strategies() {
      return groupBy(this.list, (item) => item?.strategy_name ?? 'General');
    }

  },

  methods: {
    ...mapActions({
      fetchList: 'shopify/shops/tactics/fetch',
    }),
  }

});


import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {UpdateAutomationModePayload} from '@/store/modules/shopify/shops/actions'

export default defineComponent({
  name: "AutomationStatusSelect",

  data() {
    return {
      selected: 'disabled',
    }
  },

  mounted() {
    if(this.selectedShop) {
      this.selected = this.selectedShop.automation_mode;
    }
  },

  watch: {
    'selectedShop.automation_mode'(value) {
      if(!value || value === this.selected) return;
      this.selected = value;
    }
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      options: 'shopify/dropdown/shops/automationStatuses/list',
      optionValueTextColorClassMap: 'shopify/dropdown/shops/automationStatuses/valueTextColorClassMap'
    })
  },

  methods: {
    ...mapActions({
      updateAutomationMode: 'shopify/shops/updateAutomationMode',
    }),

    getColorClassForStatus(status) {
      if(!(status in this.optionValueTextColorClassMap)) return null;
      return this.optionValueTextColorClassMap[status];
    },
    selectionChanged(value) {
      if(!this.selectedShop || this.selectedShop.automation === this.selected) return;

      this.updateAutomationMode({
        shopId: this.selectedShop.id,
        mode: value,
      } as UpdateAutomationModePayload);
    },
  }

});

<template>
  <div class="mt-6 p-0">
    <!-- Automation -->
    <b-card class="">
      <template #header>
        <div class="card-title m-0 w-100 flex-wrap flex-stack">
            <div class="flex-grow-1 pe-8">
              <h3>{{ settings.automation.label }}</h3>
            </div>
            <div class="d-flex align-items-center justify-content-end flex-stack mt-3">
              <b-spinner v-if="selectedShopIsUpdating"
                         label="Saving"
                         class="me-3"/>

              <b-button variant="primary"
                        :disabled="selectedShopIsUpdating || !canSave || !selectedShopUpdateIsValid"
                        @click="save"
                        size="sm">
                Save
              </b-button>

            </div>

        </div>
      </template>

      <template v-for="(setting, index) in settings.automation.components" v-bind:key="`shop-${selectedShop.id}-setting-automation-${index}`">
        <component v-if="setting.component || false"
                   :is="setting.component"/>
        <common-setting v-else :config="setting"/>
      </template>


    </b-card>

    <!-- Ad Scale by CPA -->
    <b-card class="mt-6">
      <template #header>
        <div class="card-title m-0 w-100 flex-wrap flex-stack">
          <div class="flex-grow-1 pe-8">
            <h3>Ad Scale by CPA settings</h3>
          </div>
          <div class="d-flex align-items-center justify-content-end flex-stack mt-3">
            <b-spinner v-if="selectedShopIsUpdating"
                       label="Saving"
                       class="me-3"/>

            <b-button variant="primary"
                      :disabled="selectedShopIsUpdating || !canSave || !selectedShopUpdateIsValid"
                      @click="save"
                      size="sm">
              Save
            </b-button>

          </div>

        </div>
      </template>

      <template v-for="(setting, index) in settings.adScaleByCpa.components" v-bind:key="`shop-${selectedShop.id}-setting-adScaleByCpa-${index}`">
        <component v-if="setting.component || false"
                   :is="setting.component"/>
        <common-setting v-else :config="setting"/>
      </template>

    </b-card>

    <!-- Products -->
    <b-card class="mt-6">
      <template #header>
        <div class="card-title m-0 w-100 flex-wrap flex-stack">
          <div class="flex-grow-1 pe-8">
            <h3> {{ settings.product.label }} </h3>
          </div>
          <div class="d-flex align-items-center justify-content-end flex-stack mt-3">
            <b-spinner v-if="selectedShopIsUpdating"
                       label="Saving"
                       class="me-3"/>

            <b-button variant="primary"
                      :disabled="selectedShopIsUpdating || !canSave || !selectedShopUpdateIsValid"
                      @click="save"
                      size="sm">
              Save
            </b-button>

          </div>

        </div>
      </template>

      <template v-for="(setting, index) in settings.product.components" v-bind:key="`shop-${selectedShop.id}-setting-product-${index}`">
        <component v-if="setting.component || false"
                   :is="setting.component"/>
        <common-setting v-else :config="setting"/>
      </template>

    </b-card>

  </div>
</template>

<script lang="ts">
import {defineComponent, shallowRef} from "vue";
import {mapActions, mapGetters} from "vuex";
import * as yup from 'yup';
import CommonSetting from "./shopSettings/common/Setting.vue"
import DailyResumeTime from "./shopSettings/automation/DailyResumeTime.vue"

import {isEqual, merge} from "lodash";

export default defineComponent({
  name: "ShopSettings",

  components: {
    CommonSetting,
    DailyResumeTime,
  },

  data() {
    return {

      settings: {
        automation: {
          label: 'Daily Management settings',
          components: [
            // {
            //   path: 'automation.target_roas',
            //   id: 'shop-settings-automation-target-roas',
            //   label: 'Target ROAS',
            //   prepend: null,
            //   description: null,
            //   placeholder: '0',
            //   validationRules: yup.number().nullable().positive().test(
            //     "maxPrecisionDigits",
            //     "decimal number can have precision of 2 digits max",
            //     (number) => {
            //       if(number == null) return false;
            //       if(Math.floor(number) === number) return true;
            //       return number.toString().split(".")[1].length <= 2;
            //     }
            //   ),
            //   normalize: {
            //     onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseFloat(value),
            //     onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value,
            //   }
            // },

            {
              path: 'automation.target_ad_cost',
              id: 'shop-settings-automation-target-ad-cost',
              label: 'Target Ad Cost',
              append: '%',
              description: null,
              placeholder: '0',
              validationRules: yup.number().nullable().positive().integer(),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value) / 100,
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value * 100,
              }
            },

            {
              path: 'automation.min_spend_daily_pause',
              id: 'shop-settings-automation-min-spend-daily-pause',
              label: 'Daily Pause Min. Spend per Ad',
              prepend: '$',
              description: null,
              placeholder: '0',
              validationRules: yup.number().nullable().positive().integer(),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value),
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value,
              }
            },

            {
              path: 'automation.daily_pause_lifetime_min_spend',
              id: 'shop-settings-automation-daily-pause-lifetime-min-spend',
              label: 'Minimum Ad Lifetime Spend',
              prepend: '$',
              description: 'Life Time Minimum Spend before sleep',
              placeholder: '0',
              validationRules: yup.number().nullable().positive().integer().min(1).max(10000000),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value),
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value,
              }
            },

            {
              path: 'automation.min_spend_losing_store_pause_7d',
              id: 'shop-settings-automation-min-spend-losing-store-pause-7-d',
              label: 'Daily Pause 7 Days Min. Spend per Ad',
              prepend: '$',
              description: null,
              placeholder: '0',
              validationRules: yup.number().nullable().positive().integer(),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value),
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value,
              }
            },

            {component: shallowRef(DailyResumeTime)},
          ]
        },

        product: {
          label: 'Products settings',
          components: [
            {
              path: 'products.product_cost_percentage',
              id: "shop-settings-products-product-cost-percentage",
              label: "Product Cost",
              append: '%',
              description: null,
              placeholder: '0',
              validationRules: yup.number().nullable().positive().integer().min(0).max(100),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value) / 100,
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value * 100,
              }
            },
          ],
        },

        adScaleByCpa: {
          label: 'Ad Scale by CPA settings',
          components: [

            {
              path: 'automation.ad_scale_by_cpa.algoma_target_cpa',
              id: 'shop-settings-automation-ad-scale-by-cpa-algoma-target-cpa',
              label: 'Algoma Target CPA',
              prepend: '$',
              description: null,
              placeholder: '0.00',
              validationRules: yup.number().integer().nullable().positive().min(1).max(1000),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value),
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value,
              }
            },

            {
              path: 'automation.ad_scale_by_cpa.facebook_target_cpa',
              id: 'shop-settings-automation-ad-scale-by-cpa-facebook-target-cpa',
              label: 'Facebook Target CPA',
              prepend: '$',
              description: null,
              placeholder: '0.00',
              validationRules: yup.number().integer().nullable().positive().min(1).max(1000),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value),
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value,
              }
            },

            {
              path: 'automation.ad_scale_by_cpa.daily_budget_stop_loss',
              id: 'shop-settings-automation-ad-scale-by-cpa-daily-budget-stop-loss',
              label: 'Daily Budget Stop Loss',
              prepend: '$',
              description: null,
              placeholder: '0',
              validationRules: yup.number().nullable().positive().integer().min(1).max(10000000),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value),
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value,
              }
            },

            {
              path: 'automation.ad_scale_by_cpa.daily_budget',
              id: 'shop-settings-automation-ad-scale-by-cpa-daily-budget',
              label: 'Daily Budget',
              prepend: '$',
              description: null,
              placeholder: '0',
              validationRules: yup.number().nullable().positive().integer().min(1).max(10000000),
              normalize: {
                onSet: (value) => (value === null || value === '' || isNaN(value)) ? null : parseInt(value),
                onGet: (value) => (value === null || value === '' || isNaN(value)) ? null : value,
              }
            },
          ],
        }
      }

    };
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      selectedShopUpdate: 'shopify/shops/selectedUpdate',
      selectedShopIsUpdating: 'shopify/shops/selectedIsUpdating',
      selectedShopUpdateIsValid: 'shopify/shops/selectedUpdateIsValid',
      tacticList: 'shopify/shops/tactics/list'
    }),

    isAdScaleTacticEnabled(): boolean {
      return this.isTacticEnabled('Ad Scale by CPA');
    },

    selectedShopUpdateSettings(): Record<string, any> {
      return this.selectedShopUpdate?.settings;
    },

    canSave(): boolean {
      // there are things to update
      if(!this.selectedShopUpdateSettings) return false;
      // update is different from saved data
      if(this.selectedShop?.settings && isEqual(merge({}, this.selectedShop?.settings || {}, this.selectedShopUpdateSettings), this.selectedShop?.settings)) return false;

      return true;
    }
  },

  methods: {
    ...mapActions({
      updateSettings: 'shopify/shops/updateSettings',
    }),

    save() {
        this.updateSettings({
          shopId: this.selectedShop.id,
          settings: this.selectedShopUpdateSettings
        });
    },

    isTacticEnabled($tacticName): boolean {
      let tactic = this.tacticList.filter(tactic => tactic.name == $tacticName)?.[0]
      return tactic?.is_active && tactic?.is_enabled
    }
  }

});
</script>

<style lang="scss" scoped>

</style>
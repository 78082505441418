
import {defineComponent} from "vue";
import AutomationStatusSelect from "./automationStatus/Select.vue";

export default defineComponent({
  name: "AutomationStatus",

  components: {
    AutomationStatusSelect,
  }

});


import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import AutomationStatus from "@/views/settings/AutomationStatus.vue";
import Tactics from "@/views/settings/Tactics.vue";
import ShopSettings from "@/views/settings/ShopSettings.vue";
import InvalidShop from '@/components/shopify/InvalidShop.vue';
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "Settings",
  components: {
    AutomationStatus,
    Tactics,
    ShopSettings,
    InvalidShop
  },

  mounted() {
    setCurrentPageTitle("Settings");
    this.fetchShopList({force: true});
    this.fetchDefaultShopSettings();
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected'
    })
  },

  methods: {
    ...mapActions({
      fetchShopList: 'shopify/shops/fetch',
      fetchDefaultShopSettings: 'shopify/shops/fetchDefaultSettings',
    }),
  },

});

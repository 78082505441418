<template>
    <b-spinner v-if="listIsFetching"
               label="Loading"
               class="ms-3"/>
    <b-card class="mt-6 p-0" :no-body="!list || !list.length"
            v-for="(tactics, strategyName) in strategies"
            :key="`strategy-item-${selectedShop.id}-${strategyName}`">
      <template #header>
        <div class="card-title  m-0">
          <h3 class="fw-bolder m-0">{{ strategyName }} Tactics</h3>

        </div>
      </template>

      <template v-if="list && list.length">
        <tactic v-for="(tactic, index) in tactics"
                :key="`tactic-item-${selectedShop.id}-${index}`"
                :tactic="tactic"/>
      </template>

    </b-card>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Tactic from "./tactics/Tactic.vue";
import {mapActions, mapGetters} from "vuex";
import {groupBy} from "lodash";

export default defineComponent({
  name: "Tactics",

  components: {
    Tactic
  },

  mounted() {
    this.fetchList();
  },

  watch: {
    'selectedShop.id'() {
      this.fetchList();
    }
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      list: 'shopify/shops/tactics/list',
      listIsFetching: 'shopify/shops/tactics/listIsFetching'
    }),
    strategies() {
      return groupBy(this.list, (item) => item?.strategy_name ?? 'General');
    }

  },

  methods: {
    ...mapActions({
      fetchList: 'shopify/shops/tactics/fetch',
    }),
  }

});
</script>

<style lang="scss" scoped>
.tactic-wrapper {
  border-bottom: 1px solid #EFF2F5;
  padding-top: 10px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>

import {defineComponent} from "vue";
import FlatPickr from 'vue-flatpickr-component';
import {mapActions, mapGetters, mapMutations} from "vuex";
import * as yup from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone);

type ComponentData = {
  flatPickrSelectedDates: null | Record<any, any>,
  flatPickrConfig: Record<any, any>,
  validation: {
    valid: boolean|null,
    rules: Record<any, any>,
    errors: [],
  }
}

export default defineComponent({
  name: "DailyResumeTime",

  components: {
    FlatPickr,
  },

  data(): ComponentData {
    return {
      flatPickrSelectedDates: null,
      flatPickrConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      },
      validation: {
        valid: null,
        rules: yup.string().nullable(),
        errors: [],
      }
    }
  },

  watch: {
    'selectedShop.id'() {
      this.validation.valid = null;
      this.validation.errors = [];
      this.onReload();
    }
  },

  mounted() {
    this.onReload();
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      selectedShopUpdate: 'shopify/shops/selectedUpdate',
    }),

    dailyResumeTime:  {
      get(): null|string {
        return this.selectedShopUpdate?.settings?.automation?.daily_resume_time ?? this.selectedShop?.settings?.automation?.daily_resume_time;
      },
      set(value) {
        this.updateSelected({
          settings: {
            automation: {
              daily_resume_time: (value === null || value === '') ? null : value
            }
          }
        });
      }
    },
  },

  methods: {
    ...mapMutations({
      updateSelected: 'shopify/shops/updateSelected',
      updateSelectedValidation: 'shopify/shops/updateSelectedValidation',
    }),

    ...mapActions({
      updateSettings: 'shopify/shops/updateSettings'
    }),

    onReload() {
      if(!this.dailyResumeTime) {
        this.flatPickrSelectedDates = null;
        return;
      }
      const utcDay = dayjs.utc().format('YYYY-MM-DD');
      let selectedDateTimestamp = dayjs.utc(utcDay+' '+this.dailyResumeTime, 'YYYY-MM-DD HH:mm').valueOf();

      this.flatPickrSelectedDates = [new Date(selectedDateTimestamp)];
    },

    onChange(selectedDates) {
      const selectedDate = selectedDates[0];
      this.dailyResumeTime = dayjs(selectedDate).tz('UTC').format('HH:mm');
    },

    validate(value) {
      this.validation.rules.validate((value === '') ? null : value)
        .then((value) => {
          this.validation.valid = (value === null) ? null : true;
          this.validation.errors = [];
        })
        .catch((err) => {
          this.validation.valid = false;
          this.validation.errors = err.errors;
        })
        .finally(() => {
          this.updateSelectedValidation({
            field: 'settings.automation.daily_resume_time',
            valid: this.validation.valid,
          });
        });
    },
  }
});

<template>
  <b-input-group>
    <el-select class="form-select-solid"
               placeholder="Select Status"
               v-model="selected"
               @change="selectionChanged">
      <template #prefix>
        <i v-if="selected" class="fas fa-circle ps-2" :class="getColorClassForStatus(selected)" />
      </template>

      <el-option v-for="(item, index) in options"
                 :label="item.label"
                 :value="item.value"
                 :key="`status-option-${index}`">
          <i class="fas fa-circle" :class="getColorClassForStatus(item.value)" />
          <span class="ps-4">{{ item.label }}</span>
      </el-option>
    </el-select>
  </b-input-group>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {UpdateAutomationModePayload} from '@/store/modules/shopify/shops/actions'

export default defineComponent({
  name: "AutomationStatusSelect",

  data() {
    return {
      selected: 'disabled',
    }
  },

  mounted() {
    if(this.selectedShop) {
      this.selected = this.selectedShop.automation_mode;
    }
  },

  watch: {
    'selectedShop.automation_mode'(value) {
      if(!value || value === this.selected) return;
      this.selected = value;
    }
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      options: 'shopify/dropdown/shops/automationStatuses/list',
      optionValueTextColorClassMap: 'shopify/dropdown/shops/automationStatuses/valueTextColorClassMap'
    })
  },

  methods: {
    ...mapActions({
      updateAutomationMode: 'shopify/shops/updateAutomationMode',
    }),

    getColorClassForStatus(status) {
      if(!(status in this.optionValueTextColorClassMap)) return null;
      return this.optionValueTextColorClassMap[status];
    },
    selectionChanged(value) {
      if(!this.selectedShop || this.selectedShop.automation === this.selected) return;

      this.updateAutomationMode({
        shopId: this.selectedShop.id,
        mode: value,
      } as UpdateAutomationModePayload);
    },
  }

});
</script>

<style scoped>

</style>

import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import objectPath from "object-path";
import {isFunction} from "lodash";


type ComponentData = {
  validation: {
    valid: boolean|null,
    errors: [],
  }
}

export default defineComponent({
  name: "Setting",

  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  data(): ComponentData {
    return {
      validation: {
        valid: null,
        errors: [],
      }
    }
  },

  watch: {
    'selectedShop.id'() {
      this.validation.valid = null;
      this.validation.errors = [];
    }
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      selectedShopUpdate: 'shopify/shops/selectedUpdate',
      defaultSettings: 'shopify/shops/defaultSettings',
    }),

    defaultSettingValue(): undefined|null|number|string {
      if(!this.defaultSettings) return null;
      return objectPath.get(this.defaultSettings, this.config.path);
    },

    settingValue:  {
      get(): null|number|string {
        let updatedSettingValue = undefined;
        if(this.selectedShopUpdate) updatedSettingValue = objectPath.get(this.selectedShopUpdate, this.config.path);
        const settingValue = (updatedSettingValue !== undefined)
          ? updatedSettingValue
          : (objectPath.get(this.selectedShop?.settings ?? {}, this.config.path) ?? null);

        return ('normalize' in this.config && 'onGet' in this.config.normalize && isFunction(this.config.normalize.onGet))
          ? this.config.normalize.onGet(settingValue)
          : settingValue;
      },
      set(value) {
        const normalizedValue = ('normalize' in this.config && 'onSet' in this.config.normalize && isFunction(this.config.normalize.onSet))
          ? this.config.normalize.onSet(value)
          : value;
        const settings = {};
        objectPath.set(settings, this.config.path, normalizedValue);

        this.updateSelected({settings});
      }
    },
  },

  methods: {
    ...mapMutations({
      updateSelected: 'shopify/shops/updateSelected',
      updateSelectedValidation: 'shopify/shops/updateSelectedValidation',
    }),

    ...mapActions({
      updateSettings: 'shopify/shops/updateSettings'
    }),

    validate(value) {
      if(!this.config.validationRules) {
        this.validation.valid = (value === null) ? null : true;
        this.validation.errors = [];
        return;
      }

      this.config.validationRules.validate((value === '') ? null : value)
        .then((value) => {
          this.validation.valid = (value === null) ? null : true;
          this.validation.errors = [];
        })
        .catch((err) => {
          this.validation.valid = false;
          this.validation.errors = err.errors;
        })
        .finally(() => {
          this.updateSelectedValidation({
            field: `settings.${this.config.path}`,
            valid: this.validation.valid,
          });
        });
    },
  }
});


import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "Tactic",

  props: {
    tactic: {
        type: Object,
        required: true,
    }
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected'
    }),
  },

  methods: {
    ...mapActions({
      enableTactic: 'shopify/shops/tactics/enable',
      disableTactic: 'shopify/shops/tactics/disable',
    }),

    handleChange(event) {
        if(!this.selectedShop) return;
        if(this.tactic.is_enabled === event.target.checked) return;

        if(event.target.checked) {
            this.enableTactic({
                shopId: this.selectedShop.id,
                tacticId: this.tactic.id,
            });
        } else {
            this.disableTactic({
                shopId: this.selectedShop.id,
                tacticId: this.tactic.id,
            });
        }
    }
  }
});
